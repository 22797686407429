import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Design from './Design';

const Profile = () => {
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const handleLogout = () => {
        // Remove token from local storage
        localStorage.removeItem('token');
        // Redirect to login page or perform any other action
        window.location.href = '/loginPage';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    const response = await axios.get('https://backend-p31o.onrender.com/gette', {
                        headers: {
                            Authorization: `Bearer ${token}` // Include the token in the Authorization header
                        }
                    });
                    setUserData(response.data); // Set user data directly from the response
                } else {
                    console.log('No token found in local storage');
                    // Remove token from local storage if it's invalid
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };

        fetchData();

        // Cleanup function if necessary
    }, []);

    // Function to handle logout
   

    // Render loading indicator while fetching data
    if (loading) {
        return <><p>Loading...if loading take more than 20 sec than consider logout and relogin </p>
         <button onClick={handleLogout} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' >Logout</button>
                 
        </>
    }

    // Render the "Design" component if userData is available
    return userData ? (
        <>
            <Design info={userData} />
           
        </>
    ) : (
        // Render a link to login page if userData is not available
        <a href='/loginPage'>Login</a>
    );
};

export default Profile;