import React, { useState } from 'react';
import axios from 'axios';

const LoginePage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      // Make a POST request to your backend login route
      const response = await axios.post('http://localhost:3001/logine', {
        username: username,
        password: password
      });

      // Extract the token from the response
      const token = response.data.token;
      localStorage.setItem('token', token);


      // Store the token in an HTTP-only cookie
   
      // Optionally, you can redirect the user to a different page after login
      // window.location.href = '/profile'; // Redirect to profile page
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="button" onClick={handleLogin}>Login</button>
      </form>
    </div>
  );
};

export default LoginePage;
