import React, { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import axios from 'axios';

const FavButton = ({ vidId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mainId, setMainId] = useState('');
  
  const addtofav = async () => {

    const userData = {
      vidId: vidId,
    };

    try {
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response = await axios.post('https://backend-p31o.onrender.com/favourites', userData);
        if (response.status === 200) {
          console.log(response.data);
          setMainId(response.data)
        }
        // Set user data directly from the response
      } else {
        console.log('No token found in local storage');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }

  };

  const buttonStyle = {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    color: isHovered ? 'pink' : 'red',
    border: 'solid',
    padding: '10px',
    borderRadius: '30px',
  };

  return (
    <>
      <button
        onClick={addtofav}
        style={buttonStyle}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <FaHeart style={{ marginRight: '5px' }} />
        Add to Favorites

      </button>
      {mainId && <p>Added Succesfully</p>}
    </>


  );
};

export default FavButton;